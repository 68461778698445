#layout-wraper {
  min-height: 100vh;
  min-width: 1440px;
  background: #f5f7f8;
  .layout-wraper-sider {
    background: #003399;
    .logo,
    .ant-menu-item {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
    }
    .ant-menu {
      background: transparent;
      border-right: none;
      a {
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .nav-title {
          line-height: 1;
          margin-top: 5px;
        }
      }
      &:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
          background: #ade9ff;
          a {
            color: #003399;
          }
        }

        li:first-child {
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
        }
      }
    }
    .sider-footer {
      position: absolute;
      bottom: 16px;
      left: 6px;
      color: #ffffff;
      line-height: 150%;
      font-weight: normal;
      p {
        margin-bottom: 6px;
        font-size: 10px;
      }
    }
  }
  .layout-wraper-header {
    height: 64px;
    background: #f5f7f8;
    border-bottom: 1px solid #cad5db;
    display: flex;
    justify-content: space-between;
    padding-left: 36px;
    padding-right: 18px;
    .left-header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
    }
    .right-header {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .content {
    padding: 18px 18px 40px;
  }
  .sidebar-logo {
    margin-top: 8px;
    cursor: default;
  }
}

.ant-dropdown.dropdown-user {
  width: 140px;
  top: 60px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  ul {
    padding: 0;
    li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
      height: 45px;
      padding-left: 16px;
      border-bottom: 1px solid #cfcfcf;
    }
  }
}
