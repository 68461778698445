.footer-create-customer {
  text-align: center;
  margin: 50px auto;
  padding: 45px 0 50px 0;
  .hint {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 24px;
  }
  .btn-submit {
    width: 280px;
    margin: 0px 8px;
  }
}
