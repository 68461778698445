.modal-upload-csv {
  .content {
    padding: 85px 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 125%;
    }
    h2{
      font-size: 14px;
      font-weight: 400;
      line-height: 125%;
      margin: 16px 0 32px 0;
    }
  }
}
