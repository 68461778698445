.right-create-order {
  padding-left: 6%;
  .table-content {
    display: flex;
    flex-direction: column;
    border: 1px solid #cad5db;
    padding: 12px 20px;
    h1 {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 5px;
    }
    a {
      color: #222222;
      font-size: 16px;
      height: 42px;
      display: flex;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 1px solid #e1e7eb;
      }
      svg {
        margin: 0 5px;
      }
    }
  }
  .hint {
    white-space: pre;
    text-align: center;
    margin: 20px 0 12px 0;
  }
  button {
    width: 100%;
  }
  .infor-office {
    background: #f5f7f8;
    margin-top: 20px;
    .phone {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      svg {
        margin-right: 10px;
      }
    }
    p {
      margin: 4px 0 8px 0;
      font-size: 12px;
    }
    span {
      font-size: 12px;
      color: #6b6b6b;
    }
    padding: 12px 16px;
  }
}
