#detail-order-page {
  min-height: 100vh;
  .content {
    padding: 20px 3% 0;
    .form-order {
      .form-order-group {
        .note {
          margin: 16px 0 24px 40px;
          font-size: 16px;
        }
      }
    }
  }
}
