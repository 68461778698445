.head-office-partners {
  .partner-content {
    margin-top: 6px;
  }
  .list-chose-partners {
    margin-right: 40px;
    ::-webkit-scrollbar {
      display: none;
    }
    .chose-partners {
      height: 350px;
      overflow: scroll;
    }
    .title {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 700;
    }
    .chose-partner {
      display: flex;
      justify-content: space-between;
      background-color: #f5f7f8;
      padding: 10px 15px 10px 12px;
      margin-bottom: 5px;
      align-items: center;
      border-radius: 4px;
    }
  }
  .table-list-partners {
    margin-top: 40px;
    .ant-pagination {
      margin: 25px 0;
    }
  }
  .msg-err {
    color: #ff4d4f;
  }
  &.disable::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
    cursor: not-allowed;
    z-index: 99999;
  }
}
