@import "../../../styles/variables";

.show-more-detail {
  width: 100%;
  justify-content: center;
  cursor: pointer;
  .ant-space-item {
    display: flex;
    align-items: center;
  }
  svg {
    transform: rotate(0);
    transition: 0.25s ease-in;
  }
  &.rotate {
    svg {
      transform: rotate(180deg);
    }
  }
}
.num-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid $primary;
}

.service-card {
  border: 1px solid #cad5db;
  .item-label {
    font-weight: 500 !important;
  }
  .item-content {
    font-weight: 400 !important;
  }
  .infor_label{
    margin-bottom: -7px;
  }
}

.service-card-hightlight {
  border: 2px solid $primary;
}
