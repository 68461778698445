.user-update {
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    padding: 45px;
    h1{
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    button {
      width: 232px;
    }
  }
}
