.table-list-order {
  .ant-checkbox-disabled {
    display: none;
  }
  .text-ellip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
  }
  .ant-table-title {
    padding: 0;
    .title {
      font-weight: bold;
      font-size: 18px;
      position: absolute;
      top: -40px;
    }
  }
  .ant-table-thead > tr > th {
    color: #222222;
  }
}
