.maintain-page {
  text-align: center;
  padding-top: 200px;
  p {
    font-size: 20px;
    margin-bottom: 0;
  }
  .name-logo {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 80px;
  }
  .title {
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 20px;
  }
  .sub-title {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 20px;
  }
  .maintain-time {
    color: #c00000;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 50px;
  }
  .bottom-bar {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    background-color: #d0cece;
    padding: 10px;
  }
  .policy {
    margin-right: 30px;
  }
  .handle-info {
    margin-right: 20px;
    font-weight: 700;
  }
}
