.right-customer-account {
  text-align: center;
  .btn-submit {
    width: 100%;
    margin-bottom: 8px;
  }
  P {
    &:first-child {
      margin-bottom: 0px;
    }
  }
}
