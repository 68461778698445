.page {
  &__container {
    background-color: white;
    margin: auto;
    padding: 64px 80px;
    width: 70%;

    @media screen and (max-width: 1179px) {
      width: 100%;
    }
    
    @media screen and (max-width: 767px) {
      padding: 64px 32px;
      width: 100%;
    }

    h2 {
      margin-top: 40px;
    }

    div {
      margin-top: 32px;
    }
  }

  &__title {
    color: #1f80b9 !important;
    font-size: 20px;
    font-weight: bold;
    text-align: center;

    &:not(:first-child) {
      margin-top: 96px;
    }
  }

  &__subtitle {
    border-bottom: 0.5px solid lightgray;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  &__description {
    line-height: 2.5;
  }
}
