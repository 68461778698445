.footer-order {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding: 45px 0;
  height: 200px;
  .hint {
    font-weight: bold;
    font-size: 20px;
  }
  .btn-submit {
    width: 280px;
    margin: 0px 8px;
  }
}
