#login-page {
  text-align: center;
  background: #fff;
  .login-image {
    background-image: url("../../assets/img/login_main_img.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    aspect-ratio: 1;
  }
  .ant-space {
    width: 100%;
  }
  .company-title {
    margin-bottom: 65px;
  }
  .login-label {
    &.login-pass {
      margin: 24px 0 40px 0;
    }
    text-align: left;
    .ant-typography-secondary {
      font-size: 12px;
    }
    .login-input {
      margin-top: 10px;
      height: 40px;
      .ant-input-password-icon {
        color: #0078cf;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .ant-input:placeholder-shown {
      font-size: 14px;
    }
    .ant-tag {
      margin: 0 8px;
    }
  }
  .login-submit {
    margin-top: 40px;
    .ant-form-item {
      margin-bottom: 16px;
    }
  }
  .login-remember {
    text-align: center;
    span {
      font-size: 16px;
    }
    .ant-checkbox-inner {
      border-color: #0078cf;
    }
    .ant-form-item {
      margin-bottom: 5px;
    }
  }
  .forgot-pw {
    text-align: center;
    .forgot-pw-link {
      color: #0078cf;
    }
  }
  .forgot-pw-link {
    cursor: pointer;
  }
  .name-logo {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 69px;
  }
}
.send-mail-dialog {
  .ant-modal-close-icon svg {
    stroke-width: 90px;
    stroke: #cfcfcf;
  }
  .ant-modal-body {
    padding: 60px;
    text-align: center;
    .ant-form-item-explain-error {
      text-align: initial;
    }
    width: 520px;
    margin: 0 auto;
    .forgot-pw-des {
      margin-bottom: 0;
    }
    .forgot-pw-des2 {
      margin-bottom: 24px;
    }
  }
  .forgot-pw-link {
    cursor: pointer;
  }
}

.confirm-dialog {
  .ant-modal-close-icon svg {
    stroke-width: 90px;
    stroke: #cfcfcf;
  }
  .ant-modal-body {
    padding: 60px 40px;
    text-align: center;
    width: 520px;
    margin: 0 auto;
    .ant-form-item-explain-error {
      text-align: initial;
    }
    h3.ant-typography {
      margin-bottom: 27px;
    }
  }
  .confirm-dialog-body {
    p {
      margin-bottom: 24px;
    }
    .ant-btn-primary {
      margin-bottom: 16px;
    }
    .forgot-pw-link {
      color: #0078cf;
      font-size: 16px;
      cursor: pointer;
    }
    .ant-card {
      margin-bottom: 24px;
      .ant-card-body {
        text-align: left;
        font-size: 12px;
        padding: 19px;
        background-color: #f5f7f8;
        ul {
          margin-top: 12px;
          padding-left: 18px;
          li {
            list-style: none;
            position: relative;
            margin-bottom: 12px;
          }

          li:before {
            content: "·";
            font-size: 20px;
            vertical-align: middle;
            line-height: 20px;
            position: absolute;
            top: -3px;
            left: -20px;
          }
        }
      }
    }
  }
}
