.base-detail-file {
  height: 160px;
  text-align: center;
  background-color: #f5f7f8;

  .delete-btn {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  img {
    height: 100%;
    width: 240px;
    object-fit: cover;
  }
}
