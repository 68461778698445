.csv-capture {
  padding: 20px 3%;
  min-height: calc(100vh - 120px);
  .header {
    margin-bottom: 24px;
    h1 {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 15px;
    }
  }
  .hint {
    font-size: 16px;
    line-height: 150%;
  }
  .upload-csv {
    margin: 24px 0 40px 0;
    height: 300px;
    border-bottom: none !important;
  }
  .btn-upload {
    display: block;
    margin: 0 auto;
    width: 280px;
    &.ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      opacity: 0.3;
      border-color: #003399;
      background: #003399;
      color: #ffffff;
    }
  }
  .upload-fail {
    padding: 15px 20px;
    background: #fff5f5;
    border-radius: 4px;
    margin-top: 24px;
    color: #c53f3f;
    font-weight: 700;
    font-size: 18px;
    svg {
      margin-right: 12px;
    }
  }
}
