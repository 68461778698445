.list-invoice-page {
  padding: 27px 24px;
  .header-invoice {
    align-items: center;
    margin-bottom: 15px;
    h1 {
      font-size: 24px;
      margin-right: 24px;
      font-weight: 700;
    }
    h2 {
      color: #ff6600;
      font-size: 18px;
      margin-left: 10px;
    }
    .info-icon {
      width: 24px;
      height: 24px;
      padding: 5px;
      border-radius: 50px;
      background-color: rgb(255, 102, 0, 0.2);
      color: #ff6600;
    }
  }
  .invoice-filter-form {
    margin-top: 20px;
  }
  .invoice-date-picker {
    margin-left: 16px;
  }
  .expiration-note {
    color: #6b6b6b;
    margin-left: 6px;
    font-size: 12px;
  }
}
