.list-invoice {
  .title {
    position: absolute;
    top: -40px;
    font-size: 18px;
    font-weight: bold;
  }

  .ant-table-thead > tr > th {
    color: #222222;
  }

  .download-invoice {
    margin-left: 20px;
    svg {
      margin-left: 5px;
    }
    cursor: pointer;
  }

  .preview-btn {
    color: #0078cf;
    cursor: pointer;
  }
}
