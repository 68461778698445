.create-customer-account-page {
  .title {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 24px;
  }
  .form-customer-account {
    margin-top: 24px;
  }
  .main-content {
    padding: 20px 3% 0;
  }
  .sale-office-title {
    font-size: 18px;
    font-weight: 700;
    margin-left: 40px;
  }

  .account-success {
    text-align: center;
    height: calc(100vh - 180px);
    padding-top: 180px;
    h1 {
      font-size: 40px;
      font-weight: 700;
      margin: 50px auto;
    }
  }
  .form-account {
    position: relative;
  }
  .form-disable::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
    cursor: not-allowed;
  }
}
