.view-invoice-modal {
  text-align: center;

  ::-webkit-scrollbar {
    display: none;
  }
  .iframe {
    width: 100%;
    height: 90vh;
  }
  .ant-modal-close {
    top: -15px;
    right: -15px;
  }
}

.react-pdf__Page__canvas {
  width: 100% !important;
}
