.link-upload {
  height: 40px;
  cursor: pointer;
  color: #0078cf;
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50px;
    display: inline-block;
    margin-bottom: 0;
  }
  svg {
    margin-left: 5px;
    path {
      fill: #0078cf;
    }
  }
}
