.image-item-view {
  .ant-image {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
    }
  }
}
.image-preview {
  .ant-image-preview-img {
    width: 600px;
    object-fit: contain;
  }
}
.sign-photo {
  .ant-image-preview-img {
    background-color: white;
  }
}
