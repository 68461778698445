.account-page {
  padding: 27px;
  width: 100%;
  height: 100%;
  .big-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .infor {
    margin-top: 24px;
    .text-center {
      line-height: 150%;
    }
    .btn-act {
      width: 232px;
      margin-top: 12px;
    }
  }
}
