.input-search {
  position: relative;
  .ant-spin {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(50%, -50%);
  }
  .content-search {
    z-index: 99;
    position: absolute;
    top: 44px;
    padding: 8px 12px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    background: #ffffff;
    .hint {
      font-size: 12px;
      color: #6b6b6b;
      height: 22px;
      margin-bottom: 8px;
    }
    .item-search {
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
}
