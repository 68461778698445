.map-marker-wrapper,
.map-marker-wrapper-highlighted,
.truck-marker, .truck-marker-unactive  {
  position: relative;
  .map-marker {
    position: absolute;
    bottom: 20px;
    left: -16px;
    background: #ffffff;
    border: 2px solid #003399;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 38px;
    color: #003399;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    transition: 0.3s;
  }
  .map-marker-con {
    position: absolute;
    bottom: 8px;
    left: 3px;
    height: 12px;
    border: 1px solid #003399;
    transition: 0.3s;
  }
  .map-marker-pointer {
    width: 8px;
    height: 8px;
    border: 2px solid #003399;
    background: #ffffff;
    border-radius: 50%;
    transition: 0.3s;
  }
}

.map-marker-wrapper-highlighted {
  .map-marker {
    bottom: 22px;
    left: -28px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    border: none;
    background: #003399;
    color: #ffffff;
    font-size: 32px;
    z-index: 10;
  }
  .map-marker-con {
    border: 1px solid #003399;
    bottom: 10px;
    left: 4px;
  }
  .map-marker-pointer {
    border: 2px solid #003399;
    width: 10px;
    height: 10px;
  }
}

.truck-marker {
  .map-marker {
    bottom: 22px;
    left: -28px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    border: none;
    background: #d63300;
    color: #ffffff;
    font-size: 32px;
    z-index: 10;
  }
  .map-marker-con {
    border: 1px solid #d63300;
    bottom: 10px;
    left: 4px;
  }
  .map-marker-pointer {
    border: 2px solid #d63300;
    width: 10px;
    height: 10px;
  }
}
.truck-marker-unactive {
  .map-marker {
    bottom: 16px;
    left: -16px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: none;
    background: #ffffff;
    color: #ffffff;
    font-size: 32px;
    z-index: 10;
    border: 2px solid #686868;
  }
  .map-marker-con {
    border: 1px solid #686868;
    bottom: 8px;
    left: 3px;
  }
  .map-marker-pointer {
    border: 2px solid #686868;
    width: 8px;
    height: 8px;
  }
}

.next-spot {
  .map-marker {
    position: absolute;
    bottom: -19px;
    left: -12px;
    line-height: 64px;
    border: none;
    font-size: 32px;
    z-index: 10;
  }

  .map-marker-pointer {
    border: 2px solid #d63300;
    width: 8px;
    height: 8px;
    border: 2px solid #d63300;
    background: #ffffff;
    border-radius: 50%;
    transition: 0.3s;
  }
}

