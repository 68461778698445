#operation-page{
  .operation-header {
    border-bottom: 1px solid #dbdbdb;
    .label {
      font-size: 12px;
      color: #68686875;
      margin-right: 4px;
    }
    .form-item {
      border: none;
    }
  }
  .content {
    padding: 0 !important;
  }
  
  .service-operation-card {
    background-color: #fff;
    border-bottom: 1px solid #cfcfcf;
    .custom-divider {
      background-color: #cfcfcf;
    }
    .item-label {
      font-weight: 400 !important;
      color: #6b6b6b !important;
    }
    .item-content {
      font-weight: 700 !important;
      font-size: 16px !important;
      margin-top: 6px !important;
    }
  }
  .service-operation-card-active {
    background-color: rgba(214, 51, 0, 0.1);
  }
  .update-button {
    z-index: 1;
    top: 16px;
    left: 50px;
  }
  
  .h-full-screen {
    height: calc(100vh - 135px) !important;
    overflow: auto;
  }
  
}
