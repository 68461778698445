.list-account-page {
  padding: 27px 24px;
  .header-account {
    margin-bottom: 15px;
    h1 {
      font-weight: 700;
      font-size: 24px;
    }
  }
  .account-filter-form {
    margin-top: 20px;
  }
}
