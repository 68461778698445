.validate-textbox {
  width: 100%;
  padding: 8px;
  background-color: #f5f7f8;
  margin-top: 8px;
  p {
    margin-bottom: 4px;
    font-size: 12px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
