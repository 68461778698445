.title-detail {
  position: relative;
  background: #f5f7f8;
  font-weight: bold;
  font-size: 20px;
  padding-left: 40px;
  margin-bottom: 8px;
  height: 56px;
  width: 100%;
  border-bottom: 1px solid #cad5db;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 56px;
    background: #2a9ec2;
  }
}

.form-item {
  margin-left: 40px;
  padding-bottom: 12px;
  margin-top: 16px;
  &:not(:last-child) {
    border-bottom: 1px solid #e1e7eb;
  }
  &:last-child {
    margin-bottom: 28px;
  }
  &.form-area {
    border: none;
    padding: 0;
  }
  .label {
    font-weight: bold;
    font-size: 16px;
    color: #6b6b6b;
    height: 40px;
    line-height: 40px;
  }
  .tag-optional {
    position: absolute;
    top: 50%;
    left: -45px;
    transform: translate(0, -50%);
  }
  .icon-help {
    position: absolute;
    right: -45px;
    top: 50%;
    transform: translate(0, -50%);
  }
  &.form-select {
  }
  &.form-radio-group {
    .ant-radio-wrapper {
      margin-right: 40px;
      font-size: 16px;
    }
    .ant-checkbox-wrapper {
      margin-right: 24px;
      font-size: 16px;
    }
  }
  &.form-multi-input {
    .sub-name-unit {
      font-size: 16px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: end;
      padding-right: 20px;
      color: #6b6b6b;
    }
    .unit {
      position: absolute;
      top: 20px;
      right: -25px;
      transform: translate(0, -50%);
      font-size: 16px;
    }
  }

  &.form-area {
    .label {
      position: relative;
      width: max-content;
      .tag-optional {
        left: initial;
        right: -45px;
      }
    }
  }

  &.form-postal-code {
    .div-search {
      height: 40px;
      font-size: 16px;
      cursor: pointer;
      svg {
        margin-right: 5px;
      }
    }
  }

  .value-confirm {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    font-size: 16px;
    padding-left: 10px;
    .div-packing {
      height: 40px;
      margin: auto 0;
    }
  }

  // upload dragger
  .ant-upload.ant-upload-drag.order-upload-dragger {
    margin-top: 8px;
    padding: 24px;
    border: 2px dashed #0078cf;
    background: white !important;
    .upload-text {
      font-weight: bold;
      font-size: 16px;
      span {
        color: rgb(0, 120, 207);
      }
      margin-bottom: 12px;
    }
    .upload-hint {
      font-size: 12px;
      color: #6b6b6b;
      width: max-content;
      text-align: left;
    }
  }
  .ant-upload-list {
    .ant-upload-list-item-info {
      .ant-upload-list-item-name {
        cursor: pointer;
        color: #0078cf;
      }
    }
  }
  .input-password {
    .ant-input-password-icon {
      color: #0078cf;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .notice-msg {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .error-msg {
    color: #ff4d4f;
  }
}

.preview-file {
  .ant-modal-close {
    top: -15px;
    right: -15px;
  }
}
