.list-order-page {
  padding: 27px 24px;
  height: 100%;
  .header-order {
    margin-bottom: 15px;
    h1 {
      font-size: 24px;
      margin: 0;
    }
    .header-right {
      button:nth-child(2) {
        margin-left: 20px;
      }
    }
  }
  .filter-group {
    margin: 20px 0 8px;
    .filter-item {
    }
  }
  .show-more-filter {
    margin-top: 55px;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
    .ant-space-item {
      display: flex;
      align-items: center;
    }
    svg {
      transform: rotate(0);
      transition: 0.25s ease-in;
    }
    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .more-filter {
    .filter-group {
      margin-top: 0;
    }
  }
  .show-delete {
    position: fixed;
    bottom: 0;
    left: 100px;
    width: calc(100% - 100px);
    height: 80px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    button {
      width: 280px;
    }
  }
}
