.my-alert {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;

  &.show {
    transition: opacity 1s ease-out;
    opacity: 1;
  }
  .ant-alert {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    margin-right: 0;
    position: absolute;
    &.ant-alert-success {
      background-color: #3ab011;
      .ant-alert-content {
        margin-right: 32px;
        .ant-alert-message {
          color: white;
        }
      }
    }
  }
}
