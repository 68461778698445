.order-success {
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  h1 {
    font-weight: bold;
    font-size: 40px;
    margin: 52px 0 48px 0;
  }
}