#create-order-page {
  .content {
    padding: 18px 18px 40px 18px !important;
    .form-order {
      .form-order-group {
        .note {
          margin: 16px 0 24px 40px;
          font-size: 16px;
        }
      }
    }
  }
}
