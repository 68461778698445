@import "~antd/dist/antd.less";

@font-family: "Noto Sans JP", sans-serif;
@primary-color: #003399;
@text-color: #222222;
@border-color-base: #cfcfcf;

@disabled-bg: #f5f5f5;
@disable-color: #999999;

// button

@btn-font-weight: 700;
@btn-primary-color: #ffffff;
@btn-border-width: 2px;
@btn-border-radius-base: 4px;
@btn-line-height: 1;

@btn-default-color: #003399;
@btn-default-bg: #ffffff;
@btn-default-border: #003399;

.ant-btn-dangerous {
  background-color: #c53f3f;
  color: #ffffff;
  border: none;
  &:focus {
    background-color: #c53f3f;
    color: #ffffff;
    border: none;
  }
}

@btn-height-lg: 56px;

// input
@input-height-base: 40px;
@input-placeholder-color: #a8a8a8;
@input-border-color: #cfcfcf;
input.ant-input:not([type="password"]),
.ant-input-password,
textarea.ant-input {
  border-radius: 4px;
  &:focus {
    box-shadow: none;
  }
  &:not([disabled]) {
    :hover {
      border-color: #0078cf;
    }
  }
}

// form
@form-item-margin-bottom: 0;

// LINK
@link-color: #0078cf;

//select
.ant-select {
  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border-radius: 4px;
      border-color: #0078cf;
    }
  }
  .ant-select-selector {
    border-radius: 4px !important;
    box-shadow: none !important;
  }
}
.ant-select-dropdown {
  padding: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .ant-select-item-option {
    min-height: 40px;
    align-items: center;
    border-bottom: 1px solid #cfcfcf;
  }
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none;
}

// radio
@radio-size: 18px;
@radio-dot-color: #0078cf;
@radio-group-item-margin-right: 40px;

// checkbox
@checkbox-size: 18px;
@checkbox-color: #0078cf;
@checkbox-group-item-margin-right: 40px;

// table
@table-padding-vertical: 10px;
@table-padding-horizontal: 12px;
@table-selected-row-bg: #fff9e6;
@table-header-bg: #f5f7f8;
@table-border-color: #cad5db;

.ant-table-column-sorters {
  justify-content: unset;
  .ant-table-column-title {
    flex: unset;
    margin-right: 5px;
  }
}

// pagination
@pagination-item-size: 27px;
@pagination-item-bg-active: #003399;
.ant-pagination-item-active a {
  color: white;
}

// datepicker
.ant-picker {
  border-radius: 4px;
  .ant-picker-clear {
    svg {
      width: 17px;
      height: 18px;
    }
  }
}

// tag
.custom-tag {
  font-size: 11px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &.tag-require {
    background: #ff6600;
    border-radius: 2px;
    width: 34px;
    height: 19px;
  }
  &.tag-optional {
    background: #222222;
    border-radius: 2px;
    width: 34px;
    height: 19px;
  }
  &.tag-sm {
    width: 75px;
    height: 24px;
    border-radius: 30px;
  }
  &.tag-lg {
    width: 79px;
    height: 28px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: bold;
  }
  &.tag-un-fix {
    background: #edeff0;
    color: #222222;
  }
  &.tag-fix {
    background: #2396ba;
  }
  &.tag-complete {
    background: #3ab011;
  }
  &.tag-un-complete {
    background: #edeff0;
    color: #222222;
  }
  &.tag-invoice-pending {
    background: #3ab011;
  }
  &.tag-invoice-complete {
    background: #edeff0;
    color: #222222;
  }
  &.tag-delivery-uncomplete {
    background: #edeff0;
    color: #222222;
  }
  &.tag-delivery-complete {
    background: #3ab011;
  }
  &.tag-delivery-return {
    background: #999999;
  }
  &.tag-service-complete {
    background: #3ab011;
  }
  &.tag-service-running {
    background: #2396ba;
  }
  &.tag-service-pending {
    color: #003399;
    border: 1px solid #003399;
  }
  &.tag-receipt {
    background: #F5F7F8;
    color: #222222;
    font-weight: 400; 
    width: 70px !important;
    margin-right: 8px !important;
  }
  &.tag-receipt-active {
    background-color: #003399;
    font-weight: 400;
    width: 70px !important;
    margin-right: 8px !important;
  }
  &.tag-sign {
    background: #F5F7F8;
    color: #222222;
    width: 90px !important;
    font-weight: 400;
    margin-right: 8px !important;
  }
  &.tag-sign-active {
    background-color: #003399;
    font-weight: 400;
    width: 90px !important;
    margin-right: 8px !important;
  }
  &.tag-updBase {
    background: #F5F7F8;
    color: #222222;
    width: 90px !important;
    font-weight: 400;
  }
  &.tag-updBase-active {
    background-color: #003399;
    font-weight: 400;
    width: 90px !important;
  }
}

// modal
.custom-modal {
  .ant-modal-content {
    padding: 60px 60px 75px;
    border-radius: 4px;
    .ant-modal-body {
      padding: 0;
      h1 {
        text-align: center;
        margin: 0 auto;
        font-size: 24px;
        font-weight: bold;
        white-space: pre;
      }
      h2 {
        text-align: center;
        margin: 16px auto 32px;
        color: #c53f3f;
        font-weight: normal;
        font-size: 14px;
      }
      .btn-action {
        width: 192px;
      }
    }
  }
}

// divider
.custom-divider {
  height: 1px;
  width: 100%;
  background-color: #cad5db;
}
