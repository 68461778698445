.App {
  font-family: "Noto Sans JP";
  // reset css
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    color: #222222;
  }
}

// width and height
$whamounts: (
  0,
  36,
  48,
  56,
  64,
  80,
  96,
  100,
  120,
  138,
  150,
  192,
  200,
  240,
  300,
  360,
  400,
  500,
  600,
  700,
  800
);
@each $space in $whamounts {
  .w-#{$space} {
    width: #{$space}px !important;
  }
  .h-#{$space} {
    height: #{$space}px !important;
  }
}

.w-full {
  width: 100% !important;
}
.h-full {
  height: 100% !important;
}

.container {
  width: 100%;
  display: flex;
  align-self: center;
  margin: auto;
}
.full-height {
  height: 100vh;
}
button.btn-text {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
.bg-white {
  background: #ffffff;
}
.text-center {
  text-align: center;
}

// Margin and Padding

.mb-5 {
  margin-bottom: 5px;
}
.mt-25 {
  margin-top: 25px;
}
.font-bold {
  font-weight: bold;
}

// flex
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// position
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}

// display
.dis-none {
  display: none;
}

.pointer {
  cursor: pointer !important;
}
